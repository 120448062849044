import React from 'react'
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { TicketHeader, TicketFooter } from '../ticket/components/ticket-components'

class TicketMonthlyDetail extends React.Component {

    render() {
        return (
            <>
                <div className='top-20'>
                    <div className='top-20'>
                        <div className='text-bold text-center text-size-20'>RECIBO DE MENSALISTA</div>
                    </div>
                </div>
                <div className='top-20'>
                    <div>Cliente: {this.props.monthly.clienteNome}</div>
                    <div>Pagamento:</div>
                    <div> {Moment(Date(this.props.monthly.createAt)).format("DD/MM/yyyy")} as {Moment(Date(this.props.monthly.createAt)).format("HH:mm")}</div>
                    <div>Valor pago: <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.props.monthly.payment.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <span>{value}</span>} /></div>
                </div>
            </>
        )
    }
}

class TicketMonthlyPlan extends React.Component {

    render() {
        return (
            <>
                <div className='top-20'>
                    <div className='top-20'>
                        <div className='text-bold text-center text-size-20'>Plano {this.props.monthly.plan.nome}</div>
                    </div>
                </div>
                <div className='top-20'>
                    <div>Horário: {this.props.monthly.clienteNome}</div>
                    <div>Das {this.props.monthly.plan.horarioInicio} as {this.props.monthly.plan.horarioFim}</div>
                    <div>Valor normal do plano</div>
                    <div><NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.props.monthly.plan.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <span>{value}</span>} /></div>
                </div>
            </>
        )
    }
}

class TicketMonthlyVehicles extends React.Component {

    render() {
        return (
            <>
                <div className='top-20'>
                    <div className='text-bold text-center text-size-20'>VEÍCULOS</div>
                </div>
                {
                    this.props.monthly.vehicle.map(item => 
                        <div className='top-20'>
                            <div>Placa: {item.placa}</div>
                            <div>Modelo: {item.modelo}</div>
                            <div>Cor: {item.cor}</div>
                        </div>
                    )
                }
            </>
        )
    }
}

class TicketMonthly extends React.Component {

    render() {
        return (
            <>
                <div className="ticket">
                    <TicketHeader estacionamento={this.props.monthly?.estacionamento} showImage={true} />
                    <TicketMonthlyDetail monthly={this.props.monthly} />
                    <TicketMonthlyPlan monthly={this.props.monthly} />
                    <TicketMonthlyVehicles monthly={this.props.monthly} />
                    <div className='top-20'>
                        <div className='text-bold text-center text-size-20'>NÃO É DOCUMENTO FISCAL</div>
                    </div>
                    <TicketFooter />
                </div>
            </>
        )
    }
}

export default TicketMonthly