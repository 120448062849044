import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import TicketView from "../view/ticket/ticket";
import WelcomeView from "../view/welcome/index";
import { RegisterLoginView, RegisterView } from "../view/register";
import { MonthlyChargesView, MonthlyChargeView } from "../view/monthly";
import { AccreditedView } from "../view/accredited";

const AllRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<TicketView />} />
            <Route exact path='/bem-vindo' element={<WelcomeView />} />
            <Route exact path='/ticket/visualizar' element={<TicketView />} />
            <Route exact path='/formulario/cadastrar' element={<RegisterView />} />
            <Route exact path='/formulario/entrar' element={<RegisterLoginView />} />
            <Route exact path='/mensalista/cobranca' element={<MonthlyChargeView />} />
            <Route exact path='/credenciado/detalhes' element={<AccreditedView />} />
            <Route exact path='/mensalista/cobrancas' element={<MonthlyChargesView />} />
        </Routes>
    </BrowserRouter>
)

export default AllRoutes