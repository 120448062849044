import React from 'react'
import { TicketLoading, TicketNotFound } from './components/ticket-components'
import TicketIn from './ticket-in/ticket-in'
import TicketOut from './ticket-out/ticket-out'
import TicketMonthly from '../monthly/monthly'
import { getAnalytics, logEvent } from "firebase/analytics";
import Moment from 'moment';
import './ticket.css'
import { firestore } from "../../config/firestore";
import { doc, getDoc, getDocs, query, where, collection } from "firebase/firestore";
import { sendScreenView } from '../../shared/analytics';
import { Receipt } from './receipt/receipt'
import { PaymentFlow } from '../ticket/payment/payment.flow'
import { getResaleColor, loadCurrentResale } from '../../config/api/resaleService'
import { MainContainer } from '../../shared/layout/system-container'

class TicketView extends React.Component {

    constructor(props) {
        super(props)
        const query = new URLSearchParams(window.location.search)
        this.state = {
            number: query.get('t'),
            parkId: query.get('e'),
            type: query.get('ty'),
            origin: query.get('origin'),
            state: 'loading',
            ticket: {
                estacionamento: {
                    nome: '',
                    documento: '',
                    endereco: '',
                    horario: '',
                    celular: ''
                }
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.number === null) {
            this.setState({ state: 'notFound' })
            return
        }
        switch (this.state.type) {
            case "s":
                this.findMonthly()
                break;
            case "note":
                this.findReceipt()
                break;
            case "ticket":
            case "tic":
                this.findTicket()
                break;
            case "new-note":
                this.findNewReceipt()
                break;
            default: break
        }
        await loadCurrentResale()
    }

    _sendEvent = (item, event, params) => {
        if (this.state.origin === "adm") {
            return;
        }
        const analytics = getAnalytics();
        logEvent(analytics, event, params);
        if (event === "view_ticket_in") {
            sendScreenView(item, "Visualizou ticket de entrada")
        } else if (event === "view_ticket_out") {
            sendScreenView(item, "Visualizou ticket de saída")
        } else if (event === "view_receipt") {
            sendScreenView(item, "Visualizou nota")
        }
    }

    isValidTimeTicket = () => {
        if (this.state.ticket.closedAt !== null && this.state.ticket.status !== 'Estacionado' && this.state.ticket.status !== 'Pago e Estacionado') {
            let checkOut = new Date(this.state.ticket.closedAt.toDate())
            var duration = Moment.duration(Moment(checkOut).diff(Moment(Date())));
            var hours = parseInt(duration.asHours())
            if (hours > 24) {
                return false
            }
        }
        return true
    }

    findMonthly = async () => {
        this.setState({ state: 'loading' })
        const docRef = doc(firestore, "monthlyHistory", this.state.number);
        const docSnap = await getDoc(docRef);
        let monthly = docSnap.data()
        if (monthly !== undefined) {
            this.setState({ monthly: monthly });
            this.setState({ type: 'monthly' })
            this.setState({ state: '' })
        } else {
            this.setState({ state: 'notFound' })
        }
    }

    findTicket = async () => {
        this.setState({ state: 'loading' })
        const docRef = collection(firestore, "ticket");
        var ticket = null
        const ticketAsNumber = parseInt(this.state.number)
        if (ticketAsNumber) {
            var q = query(docRef, where("ticket", "==", String(ticketAsNumber)), where("estacionamentoId", "==", this.state.parkId))
            const docsSnap = await getDocs(q);
            if (!docsSnap.empty) {
                ticket = docsSnap.docs[0].data()
                ticket.id = docsSnap.docs[0].id;
            }
        } else {
            const docsSnap = await getDoc(doc(firestore, 'ticket', this.state.number))
            if (docsSnap.exists) {
                ticket = docsSnap.data()
            }
        }
        if (ticket) {
            this.setState({ state: '' })
            this.setState({ ticket: ticket });
            var params = { ticket_id: this.state.number, number: ticket.ticket, estacionamentoId: ticket.estacionamentoId }
            if (this.isValidTimeTicket()) {
                if (ticket.status === 'Estacionado' || ticket.status === 'Pago e Aguardando') {
                    this._sendEvent(ticket, "view_ticket_in", params)
                    this.setState({ type: 'in' })
                } else if (ticket.status === 'Pago e Entregue' || ticket.status === 'Pago e Estacionado' || ticket.status === 'Cancelado') {
                    this.setState({ type: 'out' })
                    this._sendEvent(ticket, "view_ticket_out", params)
                }
            }
        } else {
            this._sendEvent(null, "view_ticket_not_found", { ticket_id: this.state.number })
            this.setState({ state: 'notFound' })
        }
    }

    findReceipt = async () => {
        this.setState({ state: 'loading' })
        const docRef = collection(firestore, "notes");
        const q = query(docRef, where("identifier", "==", this.state.number));
        const docsSnap = await getDocs(q);
        if (!docsSnap.empty) {
            var receipt = docsSnap.docs[0].data()
            if (receipt) {
                receipt.id = docsSnap.docs[0].id;
                receipt.estacionamentoId = receipt.estacionamento.id;
                receipt.estacionamentoNome = receipt.estacionamento.nome;
                this.setState({ type: 'note' })
                this.setState({ receipt: receipt });
                var params = { receipt: this.state.number, currentStatus: receipt.currentStatus, estacionamento: receipt.estacionamento.nome, estacionamentoId: receipt.estacionamento.id }
                this._sendEvent(receipt, "view_receipt", params)
                this.setState({ state: '' })
            } else {
                this._sendEvent(null, "view_receipt_not_found", { receipt: this.state.number })
                this.setState({ state: 'notFound' })
            }
        }
    }

    findNewReceipt = async () => {
        this.setState({ state: 'loading' })
        const docRef = collection(firestore, "newNotes");
        const q = query(docRef, where("identifier", "==", this.state.number));
        const docsSnap = await getDocs(q);
        if (!docsSnap.empty) {
            var receipt = docsSnap.docs[0].data()
            if (receipt !== undefined) {
                receipt.id = docsSnap.docs[0].id;
                this.setState({ type: 'new-note' })
                this.setState({ receipt: receipt });
                var params = { receipt: this.state.number, currentStatus: receipt.currentStatus, estacionamentoId: receipt.estacionamentoId }
                this._sendEvent(receipt, "view_receipt", params)
                this.setState({ state: '' })
            } else {
                this._sendEvent(null, "view_receipt_not_found", { receipt: this.state.number })
                this.setState({ state: 'notFound' })
            }
        } else {
            this.setState({ state: 'notFound' })
        }
    }

    ticketState = () => {
        if (this.state.state !== 'loading') {
            if (this.state.type === 'in') {
                return <TicketIn ticket={this.state.ticket} onPaymentTapped={this.onPaymentTapped} />
            } else if (this.state.type === 'out') {
                return <TicketOut ticket={this.state.ticket} />
            } else if (this.state.type === 'monthly') {
                return <TicketMonthly monthly={this.state.monthly} />
            } else if (this.state.type === 'note' || this.state.type === 'new-note') {
                return <Receipt receipt={this.state.receipt} />
            } else if (this.state.type === 'payment') {
                return <PaymentFlow ticket={this.state.ticket} />
            }
            return <TicketNotFound />
        } else if (this.state.state === 'notFound') {
            return <TicketNotFound />
        }
        return <TicketLoading />
    }

    onPaymentTapped = () => {
        this.setState({ type: 'payment' })
    }

    render() {
        return (
            <MainContainer>
                <div style={{ backgroundColor: getResaleColor(), margin: "-2.1%", paddingTop: 25, paddingBottom: 20 }} >
                    {this.ticketState()}
                </div>
            </MainContainer>
        )
    }
}

export default TicketView;