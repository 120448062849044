import React from 'react'
import '../ticket.css'
import { PromotionImage, TicketHeader, TicketQRCode, TicketPromotionalHeader, TicketMonthly, TicketFooter, TicketImageCardDetail, TicketCardDetail, TicketInternalReview, TicketExternalReview, TicketInternalNotReview, TicketProductsAndServices, TicketPermanence, TicketPayment, TicketStatusSend, TicketRequest } from './../components/ticket-components'
import { TicketPaymentButton } from './../components/ticket-payment-button'

class TicketIn extends React.Component {

    render() {
        return (
            <>
                <PromotionImage ticket={this.props.ticket} />
                <div className="ticket">
                    <TicketImageCardDetail ticket={this.props.ticket} />
                    <TicketHeader estacionamento={this.props.ticket.estacionamento} showImage={this.props.ticket.urlVehicle === null} />
                    <TicketPromotionalHeader ticket={this.props.ticket} />
                    <TicketMonthly ticket={this.props.ticket} />
                    <TicketCardDetail ticket={this.props.ticket} />
                    <TicketInternalReview ticket={this.props.ticket} />
                    <TicketProductsAndServices ticket={this.props.ticket} />
                    <TicketPermanence ticket={this.props.ticket} />
                    <TicketStatusSend ticket={this.props.ticket} />
                    <TicketExternalReview ticket={this.props.ticket} />
                    <TicketPayment ticket={this.props.ticket} />
                    <TicketQRCode ticket={this.props.ticket} />
                    {
                        this.props.ticket.status === "Estacionado" &&
                        <TicketPaymentButton ticket={this.props.ticket} onPaymentTapped={this.props.onPaymentTapped} />
                    }
                    <TicketRequest ticket={this.props.ticket} />
                    <TicketInternalNotReview ticket={this.props.ticket} />
                    <TicketFooter ticket={this.props.ticket} showSecurityMessage={true} />
                </div>
            </>
        )
    }
}

export default TicketIn