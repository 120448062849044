import React from 'react'
import '../ticket.css'
import { firestore } from "../../../config/firestore";
import { getDocs, collection, query, where } from "firebase/firestore";
import { NewNoteButton } from '../receipt/receipt'
import { PromotionImage, TicketHeader, TicketQRCode, TicketPromotionalHeader, TicketMonthly, TicketPayment, TicketFooter, TicketImageCardDetail, TicketCardDetail, TicketInternalReview, TicketExternalReview, TicketInternalNotReview, TicketProductsAndServices, TicketPermanence, TicketStatusSend } from '../components/ticket-components'

class TicketOut extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            receipt: null
        }
    }

    componentDidMount = async () => {
        const rps = this.props.ticket.rps
        if (rps) {
            const citiesRef = collection(firestore, "notes");
            const q = query(citiesRef,
                where("rpsSerie.serie", "==", rps.serie),
                where("rpsSerie.numero", "==", rps.numero),
                where("rpsSerie.estacionamentoId", "==", this.props.ticket.estacionamentoId));
            const docSnap = await getDocs(q);
            if (!docSnap.empty) {
                this.setState({ receipt: docSnap.docs[0].data() })
            }
        } else {
            const newnote = this.props.ticket.newNote
            if (newnote) {
                const c = collection(firestore, "newNotes");
                const q = query(c, where("identifier", "==", newnote.identifier))
                const docSnap = await getDocs(q);
                if (!docSnap.empty) {
                    const note = docSnap.docs[0].data()
                    this.setState({ newNote: note })
                }
            }
        }
    }

    render() {
        return (
            <>
                <PromotionImage ticket={this.props.ticket} />
                <div className="ticket">
                    <TicketPromotionalHeader ticket={this.props.ticket} />
                    <TicketImageCardDetail ticket={this.props.ticket} />
                    <TicketHeader estacionamento={this.props.ticket.estacionamento} showImage={this.props.ticket.urlVehicle === null} />
                    {
                        this.state.newNote && <NewNoteButton newNote={this.state.newNote} />
                    }
                    <TicketMonthly ticket={this.props.ticket} />
                    <div className='top-20'>
                        <div className='text-bold text-center text-size-20'>RECIBO DE PAGAMENTO</div>
                    </div>
                    <TicketCardDetail ticket={this.props.ticket} />
                    <TicketPermanence ticket={this.props.ticket} />
                    <TicketStatusSend ticket={this.props.ticket} />
                    <TicketProductsAndServices ticket={this.props.ticket} />
                    <TicketExternalReview ticket={this.props.ticket} />
                    <TicketInternalReview ticket={this.props.ticket} />
                    <TicketPayment ticket={this.props.ticket} />
                    <TicketQRCode ticket={this.props.ticket} />
                    <TicketInternalNotReview ticket={this.props.ticket} />
                    {
                        !this.state.receipt && !this.state.newNote &&
                        <div className='top-20'>
                            <div className='text-bold text-center text-size-20'>NÃO É DOCUMENTO FISCAL</div>
                        </div>
                    }
                    <TicketFooter ticket={this.props.ticket} showSecurityMessage={true} />
                </div>
            </>
        )
    }
}

export default TicketOut