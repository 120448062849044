import React from 'react'
// import './ticket/ticket.css'
// import '../../../'
import { getInitializeTicket } from '../../../config/api/ticketServicer';

class TicketPaymentButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            habilitarPagamentoTicketOnline: false
        }
    }

    componentDidMount = async () => {
        if (this.props.ticket.monthly !== null) {
            this.setState({ isLoading: false })
        } else {
            const result = await getInitializeTicket(this.props.ticket)
            this.setState({ isLoading: false })
            if (result.success) {
                this.setState({ habilitarPagamentoTicketOnline: result.data.parameter.habilitarPagamentoTicketOnline })
            } else {
                this.setState({ habilitarPagamentoTicketOnline: false })
            }
        }
    }

    onPaymentTapped = () => {
        this.props.onPaymentTapped()
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading &&
                    <span className="loader" />
                }
                {
                    this.state.habilitarPagamentoTicketOnline &&
                    <button className="btn-card top-20" onClick={this.onPaymentTapped}>Pagar Ticket</button>
                }
            </>
        )
    }
}

export { TicketPaymentButton }