import React from 'react'
import { TicketCardDetail, TicketHeader, TicketCardTotals, TicketPromotionalHeader, TicketImageCardDetail, TicketProductsAndServices } from "../components/ticket-components";

class PaymentFlow extends React.Component {

    render() {
        return (
            <>
                <div className="ticket">
                    <TicketImageCardDetail ticket={this.props.ticket} />
                    <TicketHeader estacionamento={this.props.ticket.estacionamento} showImage={this.props.ticket.urlVehicle === null} />
                    <TicketPromotionalHeader ticket={this.props.ticket} />
                    <TicketCardDetail ticket={this.props.ticket} />
                    <TicketProductsAndServices ticket={this.props.ticket} />
                    <TicketCardTotals ticket={this.props.ticket} />
                </div>
            </>
        )
    }
}

export { PaymentFlow }