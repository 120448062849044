import React from 'react'
import '../ticket.css'
import { PromotionImage, TicketHeader, TicketFooter } from '../components/ticket-components'

class Receipt extends React.Component {

    render() {
        return (
            <>
                <PromotionImage ticket={this.props.receipt} />
                <div className="ticket">
                    <TicketHeader estacionamento={this.props.receipt.estacionamento} />
                    <NewNoteButton newNote={this.props.receipt} />
                    <TicketFooter ticket={this.props.receipt} />
                </div>
            </>
        )
    }
}

class NewNoteButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hasNote: props.newNote !== null,
            pdf_nfse: props.newNote?.pdf_nfse,
            currentStatus: props.newNote?.currentStatus,
        }
    }

    render() {
        return (
            <>
                {
                    this.state.hasNote &&
                    <div className='top-20 text-center'>
                        {
                            this.state.currentStatus === "NOTE_GENERATED" &&
                            <>
                                <a href={this.state.pdf_nfse} target='_bank'>
                                    <div className='top-20 ticket-success text-bold text-center'>
                                        Esta nota fiscal foi gerada com sucesso. <p />
                                        <strong>VISUALIZAR NOTA FISCAL</strong>
                                    </div>
                                </a>
                            </>
                        }
                        {
                            this.state.currentStatus === "NOTE_PROCESSING" &&
                            <div className='top-20 ticket-warning text-bold text-center'>
                                Este RPS ainda não foi convertido em uma nota fiscal.
                            </div>
                        }
                        {
                            this.state.currentStatus === "NOTE_CANCELED" &&
                            <>
                                <a href={this.state.pdf_nfse} target='_bank'>
                                    <div className='top-20 ticket-danger text-bold text-center'>
                                        Esta nota fiscal foi cancelada. <p />
                                        <strong>VISUALIZAR NOTA FISCAL</strong>
                                    </div>
                                </a>
                            </>
                        }
                        {
                            this.state.currentStatus === "ERROR" &&
                            <div className='top-20 ticket-danger text-bold text-center'>
                                Houve um erro ao tentar converter o RPS em nota fiscal,
                                entre em contato com o estabelecimento para entender o motivo.
                            </div>
                        }
                    </div>
                }
            </>
        )
    }
}

export { Receipt, NewNoteButton }