
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { getClientById } from '../config/api/clientApi';
import { getURLFromURI } from '../config/api/filesWorker';
const { v4: uuidv4 } = require('uuid');
// const { toast } = require('react-hot-toast');

export const minutesToDescription = (minutes) => {
    minutes = minutes * 60
    var h = Math.floor(minutes / 3600);
    var m = Math.floor(minutes % 3600 / 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hora e " : " horas e ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minuto" : " minutos") : "";
    if (h === 24 && m === 0) {
        return "24 horas"
    }
    if (m === 0) {
        return (h === 1 ? h + " hora" : h + " horas")
    }
    return hDisplay + mDisplay;
}

export const searchCEP = async (value) => {
    const cep = String(value).replaceAll(' ', '').replaceAll("-", "")
    if (cep.length === 8) {
        const url = "https://viacep.com.br/ws/" + cep + "/json/"
        return new Promise(function (resolve, reject) {
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    const data = {
                        cidade: result.localidade,
                        estado: result.uf,
                        ...result
                    }
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    return null
}

export const searchIp = async () => {
    return new Promise(function (resolve, reject) {
        fetch("http://ip.jsontest.com/")
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const queryString = (key) => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    return params.get(key)
}


export const toastWarning = (message) => {
    alert(message)
    // toast(message, {
    //     style: {
    //         padding: '16px',
    //     },
    //     icon: '⚠️',
    // })
}

export const takeIfIsNotNullOrEmpty = (value) => {
    return isNullOrEmpty(value) ? null : value
}

export const toastSuccess = (message) => {
    alert(message)
    // toast.success(message, {
    //     style: {
    //         padding: '16px',
    //     },
    // })
}

export const toastError = (message) => {
    alert(message)
    // toast.error(message)
}

export const reloadWithAlert = (message) => {
    toastSuccess(message)
    setTimeout(function () {
        window.location.reload()
    }, 1000)
}

export const reloadWithAlertError = (message) => {
    toastSuccess(message)
    setTimeout(function () {
        window.location.reload()
    }, 1000)
}

export const setPathWindow = (path) => {
    window.history.pushState('', '', path)
}

export const goToWindow = (path) => {
    window.location.href = path
}

export const goToWindowWithAlert = (message, path) => {
    toastWarning(message)
    window.location.href = path
}

export const goToNewWindow = (path) => {
    window.open(path, '_blank')
}

export const toCurrency = (value) => {
    return (value ?? "0").toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const isValidatePlate = (str) => {
    const plate = str.toUpperCase().replace('-', '')
    const numbers = plate.substring(3, 7)
    const letters = plate.substring(0, 3)
    if (plate.length === 7 && letters.match(/[a-zA-Z]/)) {
        // Placa Antiga: FGR4747
        if (!isNaN(numbers)) {
            return true
        }
        // Placa Nova: AAA2A22
        const lastLetter = plate.substring(4, 5)
        if (lastLetter.match(/[a-zA-Z]/)) {
            const firstNumber = plate.substring(3, 4)
            const lastNumbers = plate.substring(5, 7)
            if (!isNaN(firstNumber) && !isNaN(lastNumbers)) {
                return true
            }
        }
    }
    return false
}

export const generateUUID = () => {
    return uuidv4()
}

export const isValidDocument = (str) => {
    const document = String(str ?? "")?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
    if (document.length === 11) {
        return cpf.isValid(document)
    } else {
        return cnpj.isValid(document)
    }
}

export const isValidCellphone = (str) => {
    const document = String(str ?? "")?.replaceAll(".", "").replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
    return document.length >= 10
}

export const isValidCNPJ = (str) => {
    const document = String(str)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
    return cnpj.isValid(document)
}

export const isValidCPF = (str) => {
    const document = String(str)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
    return cpf.isValid(document)
}

export const documentMask = (value) => {
    const document = String(value)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "").replaceAll("_", "")
    if (document.length === 11) {
        return document
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1-$2')
    } else {
        return document
            .replace(/\D+/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }
}

export const isValidEmail = (email) => {
    return String(email).toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const onlyNumbers = (str) => {
    var res = str.replace(/\D/g, '');
    return String(res)
}

export const isNumber = (str) => {
    return Number(str) === str;
}

export const isNullOrEmpty = (value) => {
    return isNull(value) || value === ""
}

export const isEmpty = (value) => {
    return value === ""
}

export const isNull = (value) => {
    return value === undefined || value === null
}

export const isValidCreditCard = (inputNum) => {
    var digit, digits, flag, sum, _i, _len;
    flag = true;
    sum = 0;
    digits = (inputNum + '').split('').reverse();
    for (_i = 0, _len = digits.length; _i < _len; _i++) {
        digit = digits[_i];
        digit = parseInt(digit, 10);
        if ((flag = !flag)) {
            digit *= 2;
        }
        if (digit > 9) {
            digit -= 9;
        }
        sum += digit;
    }
    return sum % 10 === 0;
}

export const loadClientSession = async () => {
    const cliente = JSON.parse(localStorage.getItem('currentClient'))
    const id = queryString("id")
    if (cliente?.id === id) {
        document.title = cliente.name
        document.body.style.backgroundColor = cliente.tema.color
        return cliente
    }
    if (id) {
        const result = await getClientById({ id: id })
        if (result.success) {
            var client = result.data
            if (client.tema) {
                const url = await getURLFromURI(client.tema?.logo)
                document.title = client.name
                document.body.style.backgroundColor = client.tema.color
                client.tema.logo = url
            }
            localStorage.setItem('currentClient', JSON.stringify(client))
            return client
        }
    }
}
